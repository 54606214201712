import React, { useState } from "react";
import {
  Button,
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Divider,
  Input,
} from "@chakra-ui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorMsg from "../Errors/ErrorMessage";
import authService from "../../services/authAction";
import { useDispatch } from "react-redux";
import { updateField } from "../../features/loanSlice";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { LuEyeOff } from "react-icons/lu";

function LoginWithHervest({
  isOpen,
  setIsOpen,
  setVerifyBvn,
  setOpenLoanTypeModal,
}) {
  const dispatch = useDispatch();
  const loginUserHandler = async (values) => {
    const res = await authService.loginUser(values);
    if (res) {
      const userDetails = res?.user;
      if (!userDetails?.bvn) {
        setIsOpen(false);
        setVerifyBvn(true);
        return;
      }

      dispatch(
        updateField({
          bvn: userDetails.bvn,
          last_name: userDetails?.lastName,
          email: userDetails?.email,
          first_name: userDetails?.firstName,
          dob: userDetails?.dob ? userDetails?.dob : "",
          alias: userDetails?.middle_name,
          phone: userDetails?.phoneNumber,
          nok_name: userDetails?.nok_name,
          nok_phone: userDetails?.nok_phone,
          nok_relationship: userDetails?.nok_relationship,
          // alternate_phone: userDetails?.phoneNumber2,
          // marital_status: userDetails?.maritalStatus.toLowerCase(),
          // nin: userDetails?.nin,
          state_of_origin: userDetails?.city,
          address: userDetails?.residentialAddress,
          is_bvn_verified: false,
        })
      );

      localStorage.setItem("last_accessed", Date.now());
      // navigate(`/request/${selectedValue}`);
      setIsOpen(false);
      setOpenLoanTypeModal(true);
      return;
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <Modal
        isCentered
        onClose={() => setIsOpen(isOpen)}
        isOpen={isOpen}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .required("Password is Required")
              .email("Invalid Email"),
            password: Yup.string().required("Password is required"),
          })}
          onSubmit={(values, { setSubmitting, setErrors }) =>
            loginUserHandler(values, setSubmitting, setErrors)
          }
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => (
            <ModalContent>
              <ModalHeader fontSize={["12px", "16px"]}>
                Enter Your Hervest Login Credentials
              </ModalHeader>
              {/* <ModalCloseButton /> */}
              <ModalBody>
                <form onSubmit={handleSubmit}>
                  <Box marginY={"10px"}>
                    <Text
                      paddingBottom={"10px"}
                      fontSize={["14px", "16px"]}
                      fontWeight={["500"]}
                      color="#33343D"
                      fontFamily="SatoshiBold"
                    >
                      Email
                    </Text>
                    <Input
                      id="email"
                      name="email"
                      className="form-input"
                      placeholder="Enter your Email Address"
                      value={values.bvn}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                    />
                    <ErrorMsg name="email" />
                  </Box>
                  <Box
                    marginY={"10px"}
                    style={{
                      position: "relative",
                    }}
                  >
                    <Text
                      paddingBottom={"10px"}
                      fontSize={["14px", "16px"]}
                      fontWeight={["500"]}
                      color="#33343D"
                      fontFamily="SatoshiBold"
                    >
                      Password
                    </Text>
                    <Input
                      id="password"
                      name="password"
                      className="form-input"
                      placeholder="*********"
                      value={values.bvn}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type={showPassword ? "text" : "password"}
                    />
                    <Box
                      onClick={() => setShowPassword(!showPassword)}
                      position="absolute"
                      right="10px"
                      top="40px"
                      cursor="pointer"
                    >
                      {showPassword ? (
                        <LuEyeOff
                          size={20}
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        />
                      ) : (
                        <MdOutlineRemoveRedEye
                          size={20}
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        />
                      )}
                    </Box>

                    <ErrorMsg name="password" />
                  </Box>
                </form>
              </ModalBody>
              <Divider
                my={"2"}
                bg="#5B2E4F"
                opacity={"1px"}
                height="1px"
                width="100%"
              />
              <ModalFooter>
                <Button
                  fontSize="12px"
                  border="1px solid #5B2E4F"
                  color="#5B2E4F"
                  mr={3}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  CLOSE
                </Button>

                <Button
                  onClick={handleSubmit}
                  bg={!isValid ? "gray" : "#5B2E4F"}
                  color="white"
                  opacity={"1px"}
                  fontSize="12px"
                  fontWeight="500"
                  fontFamily="satoshiBold"
                  p="13px 35px"
                  cursor={isValid ? "pointer" : "default"}
                  disabled={isSubmitting || !isValid}
                >
                  {isSubmitting ? "PROCEESSING..." : "PROCEED"}
                </Button>
              </ModalFooter>
            </ModalContent>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default LoginWithHervest;
