import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Divider, Button, Stack } from "@chakra-ui/react";
import { getAnalytics, logEvent } from "firebase/analytics";
import { fbAnalyticsApp } from "../fbanalytics";
import * as amplitude from "@amplitude/analytics-browser";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";
import FileUpload from "../components/Inputs/FileUpload";
import toast from "react-hot-toast";
import loanService from "../services/loanAction";

function Returning() {
  const [allLoanData, setAllLoanData] = useState();

  useEffect(() => {
    const getLastLoanRequestInfo = async () => {
      const loanInfo = localStorage.getItem("returning_loan_data");
      if (loanInfo) {
        setAllLoanData(JSON.parse(loanInfo));
      }
      const analytics = getAnalytics(fbAnalyticsApp);
      logEvent(analytics, "VIEWED_RETURNING_CUSTOMER_PAGE", allLoanData);
      amplitude.track("VIEWED_RETURNING_CUSTOMER_PAGE", allLoanData);
    };
    getLastLoanRequestInfo();
    // eslint-disable-next-line
  }, []);

  const submitReturningCustomerLoanHandler = async (values) => {
    const res = await loanService.submitReturnuingLoanApplication(values);
    if (res) {
      toast.success("Loan Application Submitted Successfully");
      localStorage.removeItem("returning_loan_data");
      window.location.href = `/success?id=${res.data.request_id}`;
    }
  };

  console.log(allLoanData, "THE ALL LOAN DATA AFTERRRR");

  return (
    <section>
      {allLoanData && (
        <Flex Flex justify="center">
          <Box
            Box
            w={["100%", "80%", "60%"]}
            boxShadow="0px 4px 12px 0px #D7D7D740"
            border="0.5px solid #EFEAED"
            borderRadius="10px"
          >
            <Text
              textAlign={"center"}
              fontWeight={"extrabold"}
              fontFamily={"satoshi"}
              fontSize={"xl"}
              paddingY={"10"}
            >
              SUBMIT NEW LOAN APPLICATION
            </Text>

            <Text
              p="20px"
              fontSize={["12px", "14px"]}
              fontWeight={["bold", "extrabold"]}
              color="#33343D"
            >
              Personal Information
            </Text>

            <Divider mt="5px" height="0.4px" bg="#5B2E4F" width="100%" />
            <Flex align="baseline" flexWrap="wrap" w="100%" p="20px">
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  First Name
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.first_name}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  last Name
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.last_name}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Maiden Name
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.alias || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  BVN
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.bvn || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Sex
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.gender || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Marital Status
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.marital_status || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Date of birth
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.dob?.substring(0, 10) || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Phone Number
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.phone || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Alternate Phone Number
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.alternate_phone || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Email Address
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.email || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Residential Address
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.address || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Nearest landmark
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.landmark || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Residential Status
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.home_ownership || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Signature
                </Text>
                <a
                  href={allLoanData?.signature}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text
                    paddingX={"5"}
                    paddingY={"3"}
                    backgroundColor={"#5B2E4F"}
                    rounded={"md"}
                    color="white"
                    fontWeight="extrabold"
                    fontSize="13px"
                    opacity="80%"
                  >
                    {"Preview Signature" || "-"}
                  </Text>
                </a>
              </Box>
            </Flex>
            <Text
              p="20px"
              fontSize={["12px", "14px"]}
              fontWeight={["bold", "extrabold"]}
              color="#33343D"
            >
              Employer's Information
            </Text>
            <Divider mt="10px" height="0.5px" bg="#5B2E4F" width="100%" />
            <Flex align="baseline" flexWrap="wrap" p="20px">
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Occupation
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.occupation || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Designation
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.designation || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Employer's Name
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.employer || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Office Telephone Number
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.office_no || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Alternative Phone Number
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.office_alternate_no || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Office Email Address
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.office_email || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Office Business Address
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.office_address || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Loan Amount
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.amount || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Loan Tenure
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.tenure || "-"}
                </Text>
              </Box>
              <Box p="10px" width={"100%"}>
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Purpose of Loan Request
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.purpose || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  NIN
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.nin || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Bank
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.bank_name || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Account Number
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.account_name || "-"}
                </Text>
              </Box>
            </Flex>
            <Text
              p="20px"
              fontSize={["12px", "14px"]}
              fontWeight={["500", "700"]}
              color="#33343D"
            >
              Uploaded Documents
            </Text>
            <Divider height="0.5px" bg="#5B2E4F" width="100%" />
            <Flex align="baseline" mt="10px" p="20px" flexWrap="Wrap">
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Utility Bill
                </Text>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={allLoanData?.utility_bill}
                >
                  <Text
                    paddingX={"5"}
                    paddingY={"3"}
                    backgroundColor={"#5B2E4F"}
                    rounded={"md"}
                    color="white"
                    fontWeight="extrabold"
                    fontSize="13px"
                    opacity="80%"
                  >
                    {"Preview Utility Bill" || "-"}
                  </Text>
                </a>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  PassPort Photograph
                </Text>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={allLoanData?.passport}
                >
                  <Text
                    paddingX={"5"}
                    paddingY={"3"}
                    backgroundColor={"#5B2E4F"}
                    rounded={"md"}
                    color="white"
                    fontWeight="extrabold"
                    fontSize="13px"
                    opacity="80%"
                  >
                    {"Preview Passport" || "-"}
                  </Text>
                </a>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Government ID
                </Text>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={allLoanData?.government_id}
                >
                  <Text
                    paddingX={"5"}
                    paddingY={"3"}
                    backgroundColor={"#5B2E4F"}
                    rounded={"md"}
                    color="white"
                    fontWeight="extrabold"
                    fontSize="13px"
                    opacity="80%"
                  >
                    {"Preview Government ID" || "-"}
                  </Text>
                </a>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Bank Statement
                </Text>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={allLoanData?.bank_statement}
                >
                  <Text
                    paddingX={"5"}
                    paddingY={"3"}
                    backgroundColor={"#5B2E4F"}
                    rounded={"md"}
                    color="white"
                    fontWeight="extrabold"
                    fontSize="13px"
                    opacity="80%"
                  >
                    {"Preview Bank Statement" || "-"}
                  </Text>
                </a>
              </Box>
              {allLoanData?.cacName && (
                <Box p="10px">
                  <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                    CAC Document
                  </Text>
                  <a target="_blank" rel="noreferrer" href={allLoanData?.cac}>
                    <Text
                      paddingX={"5"}
                      paddingY={"3"}
                      backgroundColor={"#5B2E4F"}
                      rounded={"md"}
                      color="white"
                      fontWeight="extrabold"
                      fontSize="13px"
                      opacity="80%"
                    >
                      {"Preview CAC" || "-"}
                    </Text>
                  </a>
                </Box>
              )}
            </Flex>
            <Text
              p="20px"
              fontSize={["12px", "14px"]}
              fontWeight={["500", "700"]}
              color="#33343D"
            >
              Next of Kin's Information
            </Text>
            <Divider height="0.5px" bg="#5B2E4F" width="100%" />
            <Flex align="baseline" mt="10px" p="20px" flexWrap="Wrap">
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Full Name
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.nok_name || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Phone Number
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.nok_phone || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Date of birth
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.nok_dob?.substring(0, 10) || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Place of work
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.nok_place_of_work || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Occupation
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.nok_occupation || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Email Address
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.nok_email || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Relationship to borrower
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.nok_relationship || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Office Business Address
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.nok_office_address || "-"}
                </Text>
              </Box>
            </Flex>
            {allLoanData?.guarantor_name && (
              <>
                <Text
                  p="20px"
                  fontSize={["12px", "14px"]}
                  fontWeight={["500", "700"]}
                  color="#33343D"
                >
                  Guarantor
                </Text>
                <Divider height="0.5px" bg="#5B2E4F" width="100%" />
                <Flex align="baseline" mt="10px" p="20px" flexWrap="Wrap">
                  <Box p="10px">
                    <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                      Guarantor Name
                    </Text>
                    <Text
                      color="#33343D"
                      fontWeight="500"
                      fontSize="13px"
                      opacity="80%"
                    >
                      {allLoanData?.guarantor_name || "-"}
                    </Text>
                  </Box>

                  <Box p="10px">
                    <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                      Guarantor Name
                    </Text>
                    <Text
                      color="#33343D"
                      fontWeight="500"
                      fontSize="13px"
                      opacity="80%"
                    >
                      {allLoanData?.guarantor_email || "-"}
                    </Text>
                  </Box>
                </Flex>
              </>
            )}

            <Text
              p="20px"
              fontSize={["12px", "14px"]}
              fontWeight={["500", "700"]}
              color="#33343D"
            >
              New Loan Details
            </Text>
            <Divider height="0.5px" bg="#5B2E4F" width="100%" />
            <Formik
              initialValues={{
                amount: "",
                tenure: "",
                type: "",
                bank_statement: "",
                statement_of_account_name: "",
                utility_bill: "",
                utility_bill_name: "",
                exisiting_request_id: allLoanData?.request_id,
              }}
              validationSchema={Yup.object({
                amount: Yup.string().required("Amount is Required"),
                tenure: Yup.string().required("Loan Tenure isRequired"),
                type: Yup.string().required("Loan Type is Required"),
                bank_statement: Yup.mixed().required(
                  "Bank Statement is Required"
                ),
                utility_bill: Yup.mixed().required("Utility Bill is Required"),
                exisiting_request_id: Yup.string(),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                console.log(values, "THE VALUES");
                await submitReturningCustomerLoanHandler(values);
                setSubmitting(false);
              }}
            >
              {({
                isSubmitting,
                handleSubmit,
                handleChange,
                handleBlur,
                handleReset,
                isValid,
                values,
                setValues,
                setErrors,
                errors,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Flex
                    spacing={6}
                    align="baseline"
                    mt="10px"
                    p="20px"
                    flexWrap="Wrap"
                  >
                    <Stack
                      direction={["column", "row"]}
                      w="100%"
                      spacing={6}
                      justify="space-between"
                    >
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                        >
                          <label htmlFor="amount">
                            Loan Amount Requested (Naira)*
                          </label>
                        </Text>
                        <input
                          className="form-input"
                          name="amount"
                          id="amount"
                          placeholder="Enter Amount"
                          min={10000}
                          max={10000000}
                          value={values.amount}
                          onChange={(e) => {
                            handleChange(e);
                            const inputValue = e.target.value;
                            console.log(inputValue, "the value");

                            const sanitizedValue = inputValue?.replace(
                              /[^0-9.]/g,
                              ""
                            );
                            if (sanitizedValue === "") {
                              return setErrors({
                                ...errors,
                                amount: "Amount is Required",
                              });
                            }

                            if (Number(sanitizedValue) < 5000) {
                              return setErrors({
                                ...errors,
                                amount:
                                  "Amount Minimum is Five (5) Thousand Naira",
                              });
                            } else if (Number(sanitizedValue) > 10000000) {
                              return setErrors({
                                ...errors,
                                amount:
                                  "Amount Limit is Ten (10) Million Naira",
                              });
                            } else if (
                              allLoanData?.type === "business" &&
                              Number(sanitizedValue) < 200000
                            ) {
                              return setErrors({
                                ...errors,
                                amount:
                                  "Minimum Amount for Business loan is Two Hundred (200) Thousand Naira",
                              });
                            } else {
                              setErrors({
                                ...errors,
                                amount: "",
                              });
                            }

                            if (sanitizedValue.length > 8) {
                              setValues({
                                ...values,
                                amount: parseFloat(
                                  sanitizedValue.toString().substring(0, 8)
                                ).toLocaleString(),
                              });
                              setErrors({
                                ...errors,
                                amount: "Amount limit reached",
                              });
                              setTimeout(() => {
                                setErrors({
                                  ...errors,
                                  amount: "Amount limit reached",
                                });
                              }, 3000);
                            } else {
                              // const formattedValue =
                              //   parseFloat(sanitizedValue).toLocaleString();
                              // setValues({
                              //   ...values,
                              //   amount: formattedValue,
                              // });
                            }
                          }}
                        />
                        {errors.amount && (
                          <div style={{ color: "red" }}>
                            <Text color="red" fontSize="11px">
                              {errors.amount}
                            </Text>
                          </div>
                        )}
                      </Box>

                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                        >
                          <label htmlFor="loanTenure">
                            Loan Tenure (Months)*
                          </label>
                        </Text>
                        <Field
                          required
                          value={values.tenure}
                          className="form-input"
                          as="select"
                          name="tenure"
                          type="number"
                        >
                          <option className="optionFields" value={0}>
                            Select An Option
                          </option>
                          {Array.from({ length: 12 }, (_, i) => i + 1).map(
                            (value) => {
                              return (
                                <option
                                  key={value}
                                  className="optionFields"
                                  value={value}
                                >
                                  {value}
                                </option>
                              );
                            }
                          )}
                        </Field>
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="tenure" />
                        </Text>
                      </Box>
                    </Stack>

                    <Stack
                      direction={["column", "row"]}
                      w="100%"
                      spacing={6}
                      justify="space-between"
                      marginTop={["20px", "20px"]}
                    >
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                        >
                          <label htmlFor="loanTenure">Application Type</label>
                        </Text>
                        <Field
                          required
                          value={values.type}
                          className="form-input"
                          as="select"
                          name="type"
                          type="number"
                          onChange={handleChange}
                        >
                          <option className="optionFields" value={0}>
                            Select An Option
                          </option>
                          {[
                            { value: "personal", label: "Personal" },
                            { value: "business", label: "Business" },
                          ].map((item) => (
                            <option
                              key={item.value}
                              className="optionFields"
                              value={item.value}
                            >
                              {item.label}
                            </option>
                          ))}
                        </Field>
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="tenure" />
                        </Text>
                      </Box>
                    </Stack>

                    <Stack
                      direction={["column", "row"]}
                      w="100%"
                      spacing={6}
                      marginTop={["20px", "20px"]}
                      justify="space-between"
                    >
                      <Box w={["100%", "50%"]}>
                        <FileUpload
                          types={["PDF"]}
                          handleChange={(file) => {
                            console.log(file, "THE FILE");
                            setValues({
                              ...values,
                              bank_statement: file,
                              statement_of_account_name: file.name,
                            });
                          }}
                          error={errors.bank_statement}
                          placeholder=" Upload Bank Statement"
                          fileName={values.statement_of_account_name}
                          title="Kindly upload your bank statement(s) for the last 6 months"
                        />
                      </Box>

                      <Box w={["100%", "50%"]}>
                        <FileUpload
                          types={["PDF", "JPEG", "PNG"]}
                          error={errors.utility_bill}
                          handleChange={(file) => {
                            console.log(file, "THE FILE BILL");
                            setValues({
                              ...values,
                              utility_bill: file,
                              utility_bill_name: file.name,
                            });
                            console.log(values, "AFTERRRRR");
                          }}
                          placeholder=" Upload Recent Utility Bill"
                          fileName={values.utility_bill_name}
                          title="Utility Bill (Recent Utility Bill)"
                        />
                      </Box>
                    </Stack>

                    <Box
                      justifyContent={["center", "flex-end"]}
                      alignItems={["center", "flex-end"]}
                      display={["block", "flex"]}
                      w="100%"
                      p={["20px 20px 20px 0"]}
                      marginTop={["20px", "20px"]}
                    >
                      <Button
                        onClick={handleSubmit}
                        isDisabled={isSubmitting || !isValid}
                        w={["100%", "auto"]}
                        type="submit"
                        bg="#5B2E4F"
                        borderRadius="6px"
                        fontSize="12px"
                        fontWeight="500"
                        color="white"
                        p={["8px 32px", "13px 52px"]}
                        _hover={{
                          color: "#5B2E4F",
                          border: "1px solid #5B2E4F",
                          bg: "white",
                        }}
                      >
                        {isSubmitting ? "PLEASE WAIT..." : "SUBMIT APPLICATION"}
                      </Button>
                    </Box>
                  </Flex>
                </Form>
              )}
            </Formik>
          </Box>
        </Flex>
      )}
    </section>
  );
}

export default Returning;
