import React from "react";
import FileUploadField from "../block-components/Input/FileUpload";
import { VStack, Text, Flex } from "@chakra-ui/react";

function FileUpload({
  title,
  handleChange,
  children,
  error,
  types,
  fileName,
  placeholder,
}) {
  return (
    <>
      <Text
        color="#33343D"
        fontWeight="500"
        fontSize="13px"
        opacity="0.8px"
        mb="5px"
      >
        <label htmlFor="id">{title}</label>
      </Text>

      <FileUploadField
        types={types}
        handleChange={(file) => handleChange(file)}
        children={
          <>
            <Flex
              cursor="pointer"
              bg="linear-gradient(0deg, #F8F1F6, #F8F1F6),linear-gradient(0deg, #5B2E4F, #5B2E4F)"
              w="100%"
              pos="relative"
              justify="center"
              align="center"
              borderRadius="6px"
              h="100px"
            >
              <VStack h="100%" align="center" justify="center">
                <Text
                  fontSize="13px"
                  color="#33343D"
                  opacity="50%"
                  fontWeight="500"
                >
                  {placeholder}
                </Text>
              </VStack>
            </Flex>
            {fileName && (
              <Text fontSize="12px" color="#540A45" fontWeight={700}>
                {fileName}
              </Text>
            )}
            {error && (
              <Text color="red" fontSize="11px">
                {error}
              </Text>
            )}
          </>
        }
      />
    </>
  );
}

export default FileUpload;
