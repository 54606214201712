import { API_BASE_URL } from "./apiConstants";
import axios from "axios";
import toast from "react-hot-toast";

const getLoanApplicationDetails = async (loanId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const { data } = await axios.get(
      `${API_BASE_URL}/loans/requests/${loanId}`,
      config
    );
    return data.data;
  } catch (error) {}
};

const submitReturnuingLoanApplication = async (payload) => {
  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    formData.append(key, payload[key]);
  });

  try {
    const { data } = await axios.post(
      `${API_BASE_URL}/loans/requests/returning`,
      formData
    );
    return data;
  } catch (err) {
    console.log(err, "THE ERROROROROR");
    toast.error(
      err.response.data.message ||
        "We couldn't process your request. Please try again later"
    );
  }
};

const loanService = {
  getLoanApplicationDetails,
  submitReturnuingLoanApplication,
};

export default loanService;
