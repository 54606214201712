import {
  Box,
  Flex,
  Text,
  Divider,
  HStack,
  VStack,
  Img,
  Button,
  Stack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import flag from "../../assets/flag.svg";
import { IoIosArrowDown } from "react-icons/io";
import { updateField, requestLoan, editLoan } from "../../features/loanSlice";
import { API_BASE_URL } from "../../services/apiConstants";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import MonoConnect from "@mono.co/connect.js";
import { ScrollToFieldError, getBase64 } from "../../services/utils/helpers";
import FileUploadField from "../block-components/Input/FileUpload";
import { getAnalytics, logEvent } from "firebase/analytics";
import { fbAnalyticsApp } from "../../fbanalytics";
import * as amplitude from "@amplitude/analytics-browser";

const SecondForm = ({ setCurrentScreen }) => {
  const request_id = localStorage.getItem("request_id");

  const refAmount = useRef(null);
  const refOccupation = useRef(null);

  const refDesignation = useRef(null);
  const refEmpName = useRef(null);
  const refOfficeTel = useRef(null);

  const refAltPhone = useRef(null);
  const refOfficeEmail = useRef(null);
  const refOfficeAddress = useRef(null);

  const refTenure = useRef(null);
  const refPurpose = useRef(refTenure);
  const refNIN = useRef(null);

  const refBank = useRef(null);
  const refBankAccNum = useRef(null);
  const refBankStatement = useRef(null);

  const refUtility = useRef(null);
  const refPassport = useRef(null);
  const refGovID = useRef(null);

  const dispatch = useDispatch();
  const toast = useToast();

  const allLoanData = useSelector((state) => state.loanData);

  const url = `${API_BASE_URL}/misc/banks`;

  const [allBanks, setAllBanks] = useState([]);
  const [loading, setLoading] = useState(false);

  //set amount
  const [amount, setAmount] = useState(allLoanData.amount || "");
  const [amountErr, setAmountErr] = useState("");

  console.log(allLoanData, "the loan data");

  //store the names of the files to display
  const [passport, setPassport] = useState(
    allLoanData?.passportName ||
      allLoanData?.passport?.split("-").splice(-1)[0] ||
      ""
  );

  console.log(refAmount, "THE AMOUNT HEREEE");

  const [id, setId] = useState(
    allLoanData?.idName ||
      allLoanData?.government_id?.split("-")?.splice(-1)[0] ||
      ""
  );
  const [utility, setUtility] = useState(
    allLoanData?.utilityName ||
      allLoanData?.utility_bill?.split("-")?.splice(-1)[0] ||
      ""
  );
  const [bankStatement, setBankStatement] = useState(
    allLoanData?.bankStatementName ||
      allLoanData?.bank_statement?.split("-").splice(-1)[0] ||
      ""
  );
  const [cacDocs, setCacDocs] = useState(
    allLoanData?.cacName || allLoanData?.cac?.split("-")?.splice(-1)[0] || ""
  );

  //store the files object
  const [passportFile, setPassportFile] = useState(
    allLoanData?.passport !== null && !allLoanData?.passport
      ? allLoanData?.passport
      : ""
  );
  const [idFile, setIdFile] = useState(
    allLoanData?.government_id ? allLoanData?.government_id : ""
  );
  const [utilityFile, setUtilityFile] = useState(
    allLoanData?.utility_bill ? allLoanData?.utility_bill : ""
  );
  const [bankStatementFile, setBankStatementFile] = useState(
    allLoanData?.bank_statement ? allLoanData?.bank_statement : ""
  );
  const [cacDocsFile, setCacDocsFile] = useState(
    allLoanData?.cac ? allLoanData?.cac : ""
  );

  const [verifiedAccountName, setVerfiedAccountName] = useState(
    allLoanData?.account_name || ""
  );
  const [notVerified, setNotVerified] = useState("");
  const [isloading, setIsLoading] = useState(false);
  // const [checkingNIN, SetcheckingNIN] = useState(false);

  const [accountDetails, setAccountDetails] = useState({
    accountNo: allLoanData?.account_no || "",
    code: allLoanData?.bank_code || "",
  });
  const [accountDetErr, setAccountDetErr] = useState({
    accountErr: "",
    codeErr: "",
  });

  const [bankName, setBankName] = useState(allLoanData?.bank_name || "");

  const [passportError, setPassportError] = useState("");
  const [idError, setIdError] = useState("");
  const [utilityError, setUtilityError] = useState("");
  const [bankStatementError, setBankStatementError] = useState("");
  const [cacDocsError, setcacDocsError] = useState("");

  const [monoCode, setMonoCode] = useState(
    allLoanData?.mono_authorisation_code
      ? allLoanData?.mono_authorisation_code
      : ""
  );

  // TEMPORARILY DISABLED MONO CONNECT FOR CUSTOMERS FOR NOW
  // eslint-disable-next-line
  const monoConnect = useMemo(() => {
    const customer = {
      name: `${allLoanData?.first_name} ${allLoanData?.last_name}`,
      email: `${allLoanData?.email}`,
      identity: {
        type: "bvn",
        number: `${allLoanData?.bvn}`,
      },
    };

    const monoInstance = new MonoConnect({
      onSuccess: ({ code }) => {
        setMonoCode(code);
      },
      key: process.env.REACT_APP_PUBLIC_KEY,
      data: { customer },
    });
    monoInstance.setup();
    return monoInstance;
  }, [
    allLoanData.first_name,
    allLoanData.last_name,
    allLoanData.email,
    allLoanData.bvn,
  ]);

  //Get all Banks
  useEffect(() => {
    const getBanks = async () => {
      const banks = await axios.get(url);
      if (banks) {
        const resp = banks?.data?.data;
        setAllBanks(resp);
      }
    };
    getBanks();
  }, [url]);

  useEffect(() => {
    const bankName = allBanks?.find(
      (item) => item.code === accountDetails?.code
    );
    setBankName(bankName);
  }, [allBanks, bankName, accountDetails?.code]);

  const getAccountName = async (accountnumber, code) => {
    setIsLoading(true);
    try {
      const resp = await axios.get(
        `${API_BASE_URL}/misc/verify_account?nuban=${accountnumber}&bankCode=${code}`
      );
      setVerfiedAccountName(resp?.data?.data?.account_name);
      setNotVerified("");
    } catch (error) {
      setNotVerified("Invalid Account Name");
      setVerfiedAccountName("");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (
      accountDetails?.accountNo?.toString()?.length === 10 &&
      accountDetails.code
    ) {
      getAccountName(accountDetails.accountNo, accountDetails.code);
    }
  }, [
    accountDetails.accountNo,
    accountDetails.code,
    accountDetails,
    allLoanData.bank_name,
  ]);

  const initialvalues = {
    occupation: allLoanData?.occupation || "",
    designation: allLoanData?.designation || "",
    name: allLoanData?.employer || "",
    officeEmail: allLoanData?.office_email || "",
    officeAddress: allLoanData?.office_address || "",
    officePhoneNumber: allLoanData?.office_no || "",
    altPhoneNumber: allLoanData?.office_alternate_no || "",
    nin: allLoanData?.nin || "",
    bankName: allLoanData?.bank_name || "",
    accountName: allLoanData?.account_name || "",
    loanTenure: allLoanData?.tenure,
    loanPurpose: allLoanData?.purpose || "",
  };

  const validationSchemaData = {
    occupation: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Only alphabetical characters are allowed")
      .required("Required"),
    designation: Yup.string().matches(
      /^[A-Za-z\s]+$/,
      "Only alphabetical characters are allowed"
    ),
    name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Only alphabetical characters are allowed")
      .required("Required"),
    officeEmail: Yup.string().required("Email is required"),
    officeAddress: Yup.string().required("Required"),
    loanPurpose: Yup.string()
      .required("Required")
      .min(40, "Loan Reason must be 40 characters or more"),
    officePhoneNumber: Yup.number()
      .required("Phone number is required")
      .test(
        "len",
        "Phone number should be 11 characters",
        (val) => val.toString().length === 10
      ),
    loanTenure: Yup.number()
      .required("Tenure is required")
      .min(1, "Tenure is required"),
    nin: Yup.string()
      .required("NIN is required")
      .min(11, "NIN must be 11 characters"),
  };

  const cleanAmount = (value) => {
    return parseInt(value?.toString()?.replace(/,/g, ""));
  };

  const GeneralError = (errorMessage, divID) => {
    toast({
      title: errorMessage,
      // description: "Please input correct account details to verify.",
      position: "bottom-right",
      status: "error",
      duration: 1000,
      isClosable: true,
    });

    divID.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    const analytics = getAnalytics(fbAnalyticsApp);
    amplitude.track("USERS_ERROR_", {
      ERROR_NAME: errorMessage,
    });
    logEvent(analytics, "USERS_ERROR_", {
      ERROR_NAME: errorMessage,
    });
  };

  const submitBtn = (values, { setSubmitting }) => {
    const analytics = getAnalytics(fbAnalyticsApp);

    if (amountErr) {
      return;
    }

    if (!amount) {
      GeneralError("Amount is Required", refAmount);
      setAmountErr("Amount is Required");
      return;
    }

    if (!accountDetails.accountNo || !accountDetails.code) {
      if (!accountDetails.accountNo && !accountDetails.code) {
        setAccountDetErr({ codeErr: "Required", accountErr: "Required" });
        return;
      }
      if (!accountDetails.accountNo) {
        GeneralError("Bank Account Number is Required", refBankAccNum);
        setAccountDetErr({ ...accountDetErr, accountErr: "Required" });
      }
      if (!accountDetails.code) {
        setAccountDetErr({ ...accountDetErr, codeErr: "Required" });
      }
      return;
    }

    if (!verifiedAccountName) {
      GeneralError("Bank Account Number is not verified", refBankAccNum);
      toast({
        title: "Account Number not verified",
        description: "Please input correct account details to verify.",
        position: "top-left",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    if (!(passport && id && utility)) {
      if (!passport) {
        GeneralError("Passport is Required", refPassport);
        setPassportError("passport is Required");
      } else {
        setPassportError("");
      }
      if (!id) {
        GeneralError("Government ID is Required", refGovID);
        setIdError("Government id is Required");
      } else {
        setIdError("");
      }
      if (!utility) {
        GeneralError("Utility Bill is Required", refUtility);
        setUtilityError("Utility bill is Required");
      } else setUtilityError("");
      return;
    }

    if (!bankStatement && !monoCode) {
      setBankStatementError("A bank statement is Required`");
      GeneralError("Bank Statement is Required", refBankStatement);
      toast({
        title: "Bank Statement is required",
        description: "Automatically connect Mono or manually upload",
        position: "top-left",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    } else {
      setBankStatementError("");
    }

    if (
      allLoanData.type === "business" &&
      allLoanData.sub_type === "corporate"
    ) {
      if (!cacDocs) {
        setcacDocsError("This document is Required");
        return;
      } else {
        setcacDocsError("");
      }
    }

    setBankStatementError("");
    setUtilityError("");
    setIdError("");
    setPassportError("");
    setAccountDetErr({ accountErr: "", codeErr: "" });

    const data = {
      occupation: values.occupation,
      designation: values.designation,
      employer: values.name,
      office_email: values.officeEmail,
      office_no: values.officePhoneNumber,
      office_address: values.officeAddress,
      office_alternate_no: values.altPhoneNumber,
      amount: cleanAmount(amount),
      tenure: values.loanTenure,
      purpose: values.loanPurpose,
      account_no: accountDetails?.accountNo,
      bank_name: bankName?.name,
      account_name: verifiedAccountName,
      bank_code: accountDetails?.code,
      nin: values?.nin,
      passportName: passport,
      idName: id,
      utilityName: utility,
      bankStatementName: bankStatement,
      passport: passportFile,
      government_id: idFile,
      utility_bill: utilityFile,
      bank_statement: bankStatementFile,
      cac: cacDocsFile,
      cacName: cacDocs,
      mono_authorisation_code: monoCode,
      verifiedNin: values?.nin,
      completed: false,
    };

    logEvent(analytics, "CLICKED_SUBMIT_SECOND_FORM", {
      purpose: values.loanPurpose,
      office_email: values.officeEmail,
      office_no: values.officePhoneNumber,
    });

    dispatch(updateField(data));
    setCurrentScreen(3);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //Save Application
  const saveDetails = async (values) => {
    const data = {
      occupation: values.occupation,
      designation: values.designation,
      employer: values.name,
      office_email: values.officeEmail,
      office_no: values.officePhoneNumber,
      office_address: values.officeAddress,
      office_alternate_no: values.altPhoneNumber,
      amount: cleanAmount(amount),
      tenure: values.loanTenure,
      purpose: values.loanPurpose,
      account_no: verifiedAccountName ? accountDetails?.accountNo : "",
      bank_name: verifiedAccountName ? bankName?.name : "",
      account_name: verifiedAccountName ? verifiedAccountName : "",
      bank_code: verifiedAccountName ? accountDetails?.code : "",
      nin: values?.nin,
      verifiedNin: values?.nin,
      passportName: passport,
      idName: id,
      utilityName: utility,
      bankStatementName: bankStatement,
      passport: passportFile,
      government_id: idFile,
      utility_bill: utilityFile,
      bank_statement: bankStatementFile,
      cac: cacDocsFile,
      cacName: cacDocs,
      mono_authorisation_code: monoCode,
      completed: false,
    };

    const analytics = getAnalytics(fbAnalyticsApp);
    logEvent(analytics, "SAVED_SECOND_FORM", {
      amount: cleanAmount(amount),
      purpose: values.loanPurpose,
      office_email: values.officeEmail,
      office_no: values.officePhoneNumber,
    });

    amplitude.track("SAVED_SECOND_FORM", {
      amount: cleanAmount(amount),
      purpose: values.loanPurpose,
      office_email: values.officeEmail,
      office_no: values.officePhoneNumber,
    });

    const event = new amplitude.Revenue()
      .setProductId("WEB_LOAN_AMOUNT_REQUESTED")
      .setPrice(cleanAmount(amount))
      .setQuantity(1);

    amplitude.revenue(event);

    const updatedData = { ...allLoanData, ...data };

    setLoading(true);

    dispatch(updateField(updatedData));
    if (request_id) {
      dispatch(editLoan(updatedData)).then((res) => {
        if (res?.payload.status === true) {
          toast({
            title: "Loan successfully saved",
            description: "Your application has been saved",
            position: "top-left",
            status: "success",
            duration: 2000,
            isClosable: true,
          });

          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          setLoading(false);
          localStorage.removeItem("wrongnin");
          return;
        } else {
          toast({
            title: "Loan not saved",
            description: "An error occurred. Please try again later",
            position: "top-left",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
      return;
    }
    dispatch(requestLoan(updatedData)).then((res) => {
      if (res?.payload.status === true) {
        toast({
          title: "Loan successfully saved",
          description: "Your application has been saved",
          position: "top-left",
          status: "success",
          duration: 2000,
          isClosable: true,
        });

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setLoading(false);
        localStorage.removeItem("wrongnin");
        return;
      } else {
        toast({
          title: "Loan not saved",
          description: "An error occurred. Please try again later",
          position: "top-left",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        setLoading(false);
      }
    });
  };

  const goBack = () => {
    setCurrentScreen(1);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const uploadUtilityHandler = (file) => {
    setUtilityError("");
    setUtility("");
    setUtilityFile("");
    // const file = e.target.files[0];
    if (file.size > 2000000) {
      setUtilityError("File size cannot be more than 2mb");
      return;
    }

    if (file) {
      setUtility(file.name);
      setUtilityFile(file);
    }
  };

  // let passportBase64 = "";
  // const [uploadPro, setUploadPro] = useState("");
  const [passportBase64, setPassportBase64] = useState("");
  const uploadPassportHandler = async (file) => {
    setPassport("");
    setPassportFile("");
    setPassportError("");

    // const params = {
    //   ACL: "public-read",
    //   Body: file,
    //   Bucket: process.env.REACT_APP_AWS_BUCKET,
    //   Key: file.name,
    // };

    // const ress = hervestBucket
    //   .putObject(params)
    //   .on("httpUploadProgress", (evt) => {
    //     console.log(evt, "THE EVTTTTT");
    //     setUploadPro(evt);
    //     // setProgress(Math.round((evt.loaded / evt.total) * 100));
    //   })
    //   .send((err) => {
    //     if (err) console.log(err);
    //   });

    // // const res = await uploadFileToAWS(file);
    // console.log(ress, "THE RES BACK RETURNNNN");
    // return;

    // const file = e.target.files[0];
    if (file.size > 2000000) {
      setPassportError("Passport size cannot be more than 2mb");
      return;
    }
    const converted = await getBase64(file);
    setPassportBase64(converted);
    setPassport(file.name);
    setPassportFile(file);
  };

  const uploadIdHandler = (file) => {
    setIdError("");
    setId("");
    setIdFile("");
    if (file.size > 2000000) {
      setIdError("ID size cannot be more than 2mb");
      return;
    }
    if (file) {
      setId(file.name);
      setIdFile(file);
    }
  };

  const uploadBankStatementHandler = async (file) => {
    setBankStatementError("");
    setBankStatement("");
    setBankStatementFile("");
    if (file.size > 2000000) {
      setBankStatementError("Bank statement size cannot be more than 2mb");
      return;
    }

    if (file) {
      setBankStatement(file.name);
      setBankStatementFile(file);
    }
  };

  return (
    <Flex justify="center">
      <Box
        w={["100%", "80%", "60%"]}
        boxShadow="0px 4px 12px 0px #D7D7D740"
        border="0.5px solid #EFEAED"
        borderRadius="10px"
      >
        <Text
          p="20px"
          fontSize={["12px", "14px"]}
          fontWeight={["500", "700"]}
          color="#33343D"
        >
          {allLoanData?.type === "personal"
            ? "EMPLOYER'S INFORMATION"
            : "BUSINESS INFORMATION"}
        </Text>
        <Divider
          color="#666666"
          opacity="85%"
          height="2px"
          width="100%"
          borderBottom="2px color=1px solid #5B2E4F1F"
        />
        <Box p={["10px", "20px"]}>
          <Formik
            initialValues={initialvalues}
            validationSchema={Yup.object(validationSchemaData)}
            onSubmit={submitBtn}
          >
            {({ values, errors, touched, handleChange }) => {
              return (
                <Form>
                  <ScrollToFieldError />
                  <VStack spacing={10}>
                    <Stack
                      direction={["column", "row"]}
                      w="100%"
                      spacing={6}
                      justify="space-between"
                    >
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                        >
                          <label htmlFor="occupation">Occupation*</label>
                        </Text>
                        <Field
                          ref={refOccupation}
                          className="form-input"
                          type="text"
                          name="occupation"
                          placeholder="occupation"
                        ></Field>
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="occupation" />
                        </Text>
                      </Box>
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                        >
                          <label htmlFor="designation">Designation</label>
                        </Text>
                        <Field
                          ref={refDesignation}
                          className="form-input"
                          name="designation"
                          placeholder="Designation"
                          type="text"
                        />
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="designation" />
                        </Text>
                      </Box>
                    </Stack>
                    <Stack
                      direction={["column", "row"]}
                      w="100%"
                      spacing={6}
                      justify="space-between"
                      alignItems="center"
                    >
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                        >
                          <label htmlFor="name">
                            {allLoanData?.type === "personal"
                              ? "Employer's Name*"
                              : "Business name*"}
                          </label>
                        </Text>
                        <Field
                          ref={refEmpName}
                          className="form-input"
                          name="name"
                          placeholder={
                            allLoanData?.type === "personal"
                              ? "Employer's Name"
                              : "Business name"
                          }
                          type="text"
                        />
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="name" />
                        </Text>
                      </Box>
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                        >
                          <label htmlFor="officePhoneNumber">
                            Office Telephone Number*
                          </label>
                        </Text>
                        <HStack
                          width="100%"
                          p="15px 10px"
                          background="linear-gradient(0deg, #F8F1F6, #F8F1F6),linear-gradient(0deg, #5B2E4F, #5B2E4F)"
                          border="0.17px solid rgba(91, 46, 79, 1)"
                          borderRadius="6px"
                        >
                          <HStack spacing={1} pl="5px" mr="10px">
                            <Img
                              src={flag}
                              borderRadius="5px"
                              objectFit="contain"
                            />
                            <Text
                              color="#33343D"
                              fontSize="12px"
                              fontWeight="500"
                              opacity="50%"
                            >
                              +234
                            </Text>
                            <Box color="#848481">
                              <IoIosArrowDown />
                            </Box>
                          </HStack>
                          <Field
                            ref={refOfficeTel}
                            className="form-input-phonenumber"
                            name="officePhoneNumber"
                            placeholder="Phone Number"
                            type="number"
                          />
                        </HStack>
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="officePhoneNumber" />
                        </Text>
                      </Box>
                    </Stack>
                    <Stack
                      alignItems="center"
                      direction={["column", "row"]}
                      w="100%"
                      spacing={6}
                      justify="space-between"
                    >
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                        >
                          <label htmlFor="altPhoneNumber">
                            Alternative Phone Number
                          </label>
                        </Text>
                        <HStack
                          width="100%"
                          p="15px 10px"
                          background="linear-gradient(0deg, #F8F1F6, #F8F1F6),linear-gradient(0deg, #5B2E4F, #5B2E4F)"
                          border="0.17px solid rgba(91, 46, 79, 1)"
                          borderRadius="6px"
                        >
                          <HStack spacing={1} pl="5px" mr="10px">
                            <Img
                              src={flag}
                              borderRadius="5px"
                              objectFit="contain"
                            />
                            <Text
                              color="#33343D"
                              fontSize="12px"
                              fontWeight="500"
                              opacity="50%"
                            >
                              +234
                            </Text>
                            <Box color="#848481">
                              <IoIosArrowDown />
                            </Box>
                          </HStack>
                          <Field
                            ref={refAltPhone}
                            className="form-input-phonenumber"
                            name="altPhoneNumber"
                            placeholder="Phone Number"
                            type="number"
                          />
                        </HStack>
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="altPhoneNumber" />
                        </Text>
                      </Box>
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                        >
                          <label htmlFor="officeEmail">
                            Office Email Address*
                          </label>
                        </Text>
                        <Field
                          ref={refOfficeEmail}
                          className="form-input"
                          name="officeEmail"
                          placeholder="Enter your mail"
                          type="text"
                        />
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="officeEmail" />
                        </Text>
                      </Box>
                    </Stack>
                    <Box w="100%">
                      <Text
                        color="#33343D"
                        fontWeight="500"
                        fontSize="13px"
                        opacity="0.8px"
                      >
                        <label htmlFor="officeAddress">
                          Office/Business Address*
                        </label>
                      </Text>
                      <Field
                        ref={refOfficeAddress}
                        as="textarea"
                        className="form-textarea"
                        name="officeAddress"
                        placeholder="Enter Address"
                        type="text"
                      />
                      <Text color="red" fontSize="11px">
                        <ErrorMessage name="officeAddress" />
                      </Text>
                    </Box>
                    <Divider
                      mt="10px"
                      height="0.4px"
                      bg="#5B2E4F"
                      width="100%"
                    />
                    <Stack
                      direction={["column", "row"]}
                      w="100%"
                      spacing={6}
                      justify="space-between"
                    >
                      {/* <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                        >
                          <label htmlFor="amount">
                            Loan Amount Requested (Naira)*
                          </label>
                        </Text>

                        <input
                          ref={refAmount}
                          className="form-input"
                          name="amount"
                          placeholder="Enter Amount"
                          min={10000}
                          max={10000000}
                          value={amount}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            console.log(inputValue, "the value");

                            const sanitizedValue = inputValue?.replace(
                              /[^0-9.]/g,
                              ""
                            );

                            if (sanitizedValue === "") {
                              // GeneralError('Amount is Required', refAmount)
                              setAmount("");
                              setAmountErr("Amount is Required");
                              return;
                            }

                            if (Number(sanitizedValue) < 10000) {
                              // GeneralError('Amount Minimum is Ten (10) Thousand Naira', refAmount)
                              setAmountErr(
                                "Amount Minimum is Ten (10) Thousand Naira"
                              );
                              // return
                            }

                            if (Number(sanitizedValue) > 10000000) {
                              // GeneralError('Amount Limit is Ten (10) Million Naira', refAmount)
                              setAmountErr(
                                "Amount Limit is Ten (10) Million Naira"
                              );
                              return;
                            }

                            // if (allLoanData?.type === "business") {
                            //   if (Number(sanitizedValue) < 200000) {
                            //     setAmountErr(
                            //       "Minimum Amount for Business loan is Two Hundred Thousand Naira (200,000)"
                            //     );
                            //     return;
                            //   }
                            // }

                            if (sanitizedValue.length > 8) {
                              setAmount(
                                parseFloat(
                                  sanitizedValue.toString().substring(0, 8)
                                ).toLocaleString()
                              );
                              setAmountErr("Amount limit reached");
                              setTimeout(function () {
                                setAmountErr("");
                              }, 3000);
                              return;
                            }
                            const formattedValue =
                              parseFloat(sanitizedValue).toLocaleString();
                            setAmount(formattedValue);
                            setAmountErr("");
                          }}
                        />
                        <div style={{ color: "red" }}>
                          <Text color="red" fontSize="11px">
                            {amountErr}
                          </Text>
                        </div>

                        <Text color="red" fontSize="11px"></Text>
                      </Box> */}
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                        >
                          <label htmlFor="amount">
                            Loan Amount Requested (Naira)*
                          </label>
                        </Text>
                        <input
                          ref={refAmount}
                          className="form-input"
                          name="amount"
                          placeholder="Enter Amount"
                          min={10000}
                          max={10000000}
                          value={amount}
                          onChange={(e) => {
                            const inputValue = e.target.value;

                            console.log(inputValue, "the value");

                            const sanitizedValue = inputValue?.replace(
                              /[^0-9.]/g,
                              ""
                            );

                            if (sanitizedValue === "") {
                              setAmount("");
                              setAmountErr("Amount is Required");
                              return;
                            }

                            if (Number(sanitizedValue) < 5000) {
                              setAmountErr(
                                "Amount Minimum is Five (5) Thousand Naira"
                              );
                              // } else if (Number(sanitizedValue) > 10000000) {
                              //   setAmountErr(
                              //     "Amount Limit is Ten (10) Million Naira"
                              //   );
                            } else if (
                              allLoanData?.type === "business" &&
                              Number(sanitizedValue) < 200000
                            ) {
                              setAmountErr(
                                "Minimum Amount for Business loan is Two Hundred (200) Thousand Naira"
                              );
                            } else {
                              setAmountErr("");
                            }

                            if (sanitizedValue.length > 8) {
                              setAmount(
                                parseFloat(
                                  sanitizedValue.toString().substring(0, 8)
                                ).toLocaleString()
                              );
                              setAmountErr("Amount limit reached");
                              setTimeout(() => {
                                setAmountErr("");
                              }, 3000);
                            } else {
                              const formattedValue =
                                parseFloat(sanitizedValue).toLocaleString();
                              setAmount(formattedValue);
                            }
                          }}
                        />
                        <div style={{ color: "red" }}>
                          <Text color="red" fontSize="11px">
                            {amountErr}
                          </Text>
                        </div>
                        <Text color="red" fontSize="11px"></Text>
                        {/* {allLoanData?.type === "business" && amount && (
                          <>
                            <Text>NB: REQUIRED DOCUMENTATION INCLUDE</Text>
                            <div>
                              {businessLoanrequiredCriterials
                                .find(
                                  (req) =>
                                    Number(req?.minimumAmount) <= amount &&
                                    Number(req?.maximumAmount) > amount
                                )
                                ?.map((item, index) => (
                                  <div key={index}>
                                    <h3>Loan Requirements</h3>
                                    {item.requirements &&
                                      item.requirements.map((req, idx) => (
                                        <p key={idx}>{req}</p>
                                      ))}
                                    <h3>Collaterals</h3>
                                    {item.collaterals &&
                                      item.collaterals.map(
                                        (collateral, idx) => (
                                          <p key={idx}>{collateral}</p>
                                        )
                                      )}
                                  </div>
                                ))}
                            </div>

                            {businessLoanrequiredCriterials.map((item) => (
                              <>
                                {Number(amount) >= item.maximumAmount &&
                                  Number(amount) <= item.maximumAmount && (
                                    <Text
                                      color="red"
                                      fontSize="11px"
                                      fontWeight="500"
                                      textTransform="uppercase"
                                    >
                                      {item?.requirements?.map((req) => (
                                        <Text>{req}</Text>
                                      ))}
                                    </Text>
                                  )}
                              </>
                            ))}
                          </>
                        )} */}
                      </Box>

                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                        >
                          <label htmlFor="loanTenure">
                            Loan Tenure (Months)*
                          </label>
                        </Text>
                        <Field
                          required
                          ref={refTenure}
                          className="form-input"
                          as="select"
                          name="loanTenure"
                          type="number"
                        >
                          <option className="optionFields" value={0}>
                            Select An Option
                          </option>
                          {Array.from({ length: 12 }, (_, i) => i + 1).map(
                            (value) => {
                              return (
                                <option
                                  key={value}
                                  className="optionFields"
                                  value={value}
                                >
                                  {value}
                                </option>
                              );
                            }
                          )}
                        </Field>
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="loanTenure" />
                        </Text>
                      </Box>
                    </Stack>
                    <Box w="100%">
                      <Text
                        color="#33343D"
                        fontWeight="500"
                        fontSize="13px"
                        opacity="0.8px"
                      >
                        <label htmlFor="loanPurpose">
                          Purpose of Loan Request*
                        </label>
                      </Text>
                      <Field
                        ref={refPurpose}
                        as="textarea"
                        className="form-textarea"
                        name="loanPurpose"
                        placeholder="Enter the purpose of loan e.g rent, education"
                        type="text"
                      />
                      <Text color="red" fontSize="11px">
                        <ErrorMessage name="loanPurpose" />
                      </Text>
                    </Box>
                    <Divider
                      mt="10px"
                      height="0.5px"
                      bg="#5B2E4F"
                      width="100%"
                    />
                    <Box w={["100%"]}>
                      <Text
                        color="#33343D"
                        fontWeight="500"
                        fontSize="13px"
                        opacity="0.8px"
                      >
                        <label htmlFor="nin">
                          National Identification Number (NIN)
                        </label>
                      </Text>

                      <Field
                        ref={refNIN}
                        className="form-input"
                        name="nin"
                        value={values.nin}
                        placeholder="Enter NIN"
                        type="text"
                        maxLength={11}
                      />

                      <Text color="red" fontSize="11px">
                        <ErrorMessage name="nin" />
                      </Text>
                    </Box>
                    <Stack
                      direction={["column", "row"]}
                      w="100%"
                      spacing={6}
                      justify="space-between"
                    >
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                        >
                          <label htmlFor="bank">Bank</label>
                        </Text>
                        <Field
                          ref={refBank}
                          className="form-input"
                          value={accountDetails.code}
                          as="select"
                          placeholder="Enter Bank Account"
                          type="text"
                          name="account"
                          onChange={(e) =>
                            setAccountDetails({
                              ...accountDetails,
                              code: e.target.value,
                            })
                          }
                        >
                          <option className="optionFields">Select bank</option>
                          {allBanks.map((item) => {
                            return (
                              <option
                                key={item.code}
                                value={item.code}
                                className="optionFields"
                              >
                                {item.name}
                              </option>
                            );
                          })}
                        </Field>

                        <Text color="red" fontSize="11px">
                          {!accountDetails.code && accountDetErr.codeErr}
                        </Text>
                      </Box>
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                        >
                          <label htmlFor="accountNumber">
                            Bank Account Number
                          </label>
                        </Text>
                        <Field
                          ref={refBankAccNum}
                          className="form-input"
                          name="accountNumber"
                          value={accountDetails.accountNo}
                          placeholder="Enter Account Number"
                          maxLength={10}
                          type="text"
                          onChange={(e) => {
                            const input = e.target.value;
                            const numericValue = input.replace(/\D/g, "");
                            setAccountDetails({
                              ...accountDetails,
                              accountNo: numericValue,
                            });
                            setVerfiedAccountName("");
                          }}
                        />
                        <Text color="red" fontSize="11px">
                          {!accountDetails?.accountNo &&
                            accountDetErr.accountErr}
                        </Text>
                        {isloading ? (
                          <div className="spinner"></div>
                        ) : accountDetails?.accountNo &&
                          accountDetails?.code ? (
                          verifiedAccountName ? (
                            <Text
                              color="#666666"
                              fontSize="13px"
                              fontWeight="500"
                            >
                              {verifiedAccountName}
                            </Text>
                          ) : (
                            <Text
                              color="red"
                              fontSize="13px"
                              fontWeight="500"
                              textTransform="uppercase"
                            >
                              {notVerified}
                            </Text>
                          )
                        ) : (
                          ""
                        )}
                      </Box>
                    </Stack>
                  </VStack>
                  <Divider
                    mt="50px"
                    color="#5B2E4F"
                    opacity="85%"
                    height="2px"
                    width="100%"
                    mb="20px"
                  />
                  <Text
                    color="#33343D"
                    fontSize="14px"
                    fontWeight="700"
                    fontFamily="SatoshiBold"
                    lineHeight="19px"
                  >
                    UPLOAD DOCUMENTS{" "}
                    <span style={{ fontWeight: "500" }}>
                      (upload format should can be in png, jpg, jpeg or pdf max
                      2mb)
                    </span>
                  </Text>
                  <VStack direction={["column", "row"]} spacing={5} mt="30px">
                    <Stack
                      direction={["column", "column", "row"]}
                      w="100%"
                      spacing={6}
                      justify="space-between"
                    >
                      <Box w={["100%", "100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                          mb="5px"
                        >
                          <label htmlFor="utility" fontFamily="SatoshiBold">
                            Utility Bill
                          </label>
                        </Text>

                        <FileUploadField
                          types={["PDF", "JPEG", "JPG", "PNG"]}
                          handleChange={(file) => uploadUtilityHandler(file)}
                          children={
                            <>
                              <Flex
                                cursor="pointer"
                                bg="linear-gradient(0deg, #F8F1F6, #F8F1F6),linear-gradient(0deg, #5B2E4F, #5B2E4F)"
                                w="100%"
                                pos="relative"
                                justify="center"
                                align="center"
                                borderRadius="6px"
                                h="100px"
                              >
                                <VStack
                                  h="100%"
                                  align="center"
                                  justify="center"
                                  cursor="pointer"
                                >
                                  <Text
                                    fontSize="13px"
                                    color="#33343D"
                                    opacity="50%"
                                    fontWeight="500"
                                    cursor="pointer"
                                  >
                                    Upload Utility Bill
                                  </Text>
                                </VStack>
                              </Flex>

                              {utility && (
                                <Text
                                  fontSize="11px"
                                  color="#540A45"
                                  fontWeight={700}
                                >
                                  {utility}
                                </Text>
                              )}
                              {utilityError && (
                                <Text color="red" fontSize="11px">
                                  {!utility && utilityError}
                                </Text>
                              )}
                            </>
                          }
                        />
                      </Box>

                      <Box w={["100%", "100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                          mb="5px"
                        >
                          <label htmlFor="passport" fontFamily="SatoshiBold">
                            PassPort Photograph
                          </label>
                        </Text>

                        <FileUploadField
                          types={["JPEG", "JPG", "PNG"]}
                          handleChange={(file) => uploadPassportHandler(file)}
                          children={
                            <>
                              <Flex
                                cursor="pointer"
                                bg="linear-gradient(0deg, #F8F1F6, #F8F1F6),linear-gradient(0deg, #5B2E4F, #5B2E4F)"
                                w="100%"
                                pos="relative"
                                justify="center"
                                align="center"
                                borderRadius="6px"
                                h="100px"
                              >
                                <VStack
                                  h="100%"
                                  align="center"
                                  justify="center"
                                >
                                  {passportBase64 ? (
                                    <Box>
                                      <img
                                        style={styles.image}
                                        alt="img"
                                        src={passportBase64}
                                      />
                                    </Box>
                                  ) : (
                                    <Text
                                      cursor="pointer"
                                      fontSize="13px"
                                      color="#33343D"
                                      opacity="50%"
                                      fontWeight="500"
                                    >
                                      Upload Photo
                                    </Text>
                                  )}
                                </VStack>
                              </Flex>

                              {passport && (
                                <Text
                                  fontSize="12px"
                                  color="#540A45"
                                  fontWeight={700}
                                >
                                  {passport}
                                </Text>
                              )}
                              {passportError && (
                                <Text color="red" fontSize="11px">
                                  {!passport && passportError}
                                </Text>
                              )}
                            </>
                          }
                        />
                      </Box>

                      <Box w={["100%", "100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                          mb="5px"
                        >
                          <label htmlFor="id">Government ID</label>
                        </Text>

                        <FileUploadField
                          types={["PDF", "JPEG", "JPG", "PNG"]}
                          handleChange={(file) => uploadIdHandler(file)}
                          children={
                            <>
                              <Flex
                                cursor="pointer"
                                bg="linear-gradient(0deg, #F8F1F6, #F8F1F6),linear-gradient(0deg, #5B2E4F, #5B2E4F)"
                                w="100%"
                                pos="relative"
                                justify="center"
                                align="center"
                                borderRadius="6px"
                                h="100px"
                              >
                                <VStack
                                  h="100%"
                                  align="center"
                                  justify="center"
                                >
                                  <Text
                                    fontSize="13px"
                                    color="#33343D"
                                    opacity="50%"
                                    fontWeight="500"
                                  >
                                    upload ID
                                  </Text>
                                </VStack>
                              </Flex>
                              {id && (
                                <Text
                                  fontSize="12px"
                                  color="#540A45"
                                  fontWeight={700}
                                >
                                  {id}
                                </Text>
                              )}
                              {idError && (
                                <Text color="red" fontSize="11px">
                                  {!id && idError}
                                </Text>
                              )}
                            </>
                          }
                        />
                      </Box>
                    </Stack>

                    <Divider mt="5px" height="1px" bg="#5B2E4F" width="100%" />

                    <Text
                      cursor="pointer"
                      fontSize="13px"
                      color="#5B2E4F"
                      fontWeight="900"
                    >
                      Kindly upload your bank statement(s) for the last 6 months
                    </Text>
                    {
                      <Stack
                        direction={["column", "row"]}
                        w="100%"
                        spacing={6}
                        justify="space-between"
                        h={["300px", "100px"]}
                      >
                        {!monoCode && (
                          <Box w={["100%", "50%"]} h="50%" mt={[3, 0, 0]}>
                            <Text
                              color="#33343D"
                              fontWeight="500"
                              fontSize="13px"
                              opacity="0.8px"
                              mb="5px"
                            >
                              <label htmlFor="bankStatement">
                                Upload Bank Statement (Last 6 Months)
                              </label>
                            </Text>
                            <FileUploadField
                              types={["PDF", "JPEG", "JPG", "PNG"]}
                              file={bankStatement}
                              handleChange={(file) =>
                                uploadBankStatementHandler(file)
                              }
                              children={
                                <>
                                  <Flex
                                    cursor="pointer"
                                    bg="linear-gradient(0deg, #F8F1F6, #F8F1F6),linear-gradient(0deg, #5B2E4F, #5B2E4F)"
                                    w="100%"
                                    pos="relative"
                                    justify="center"
                                    align="center"
                                    borderRadius="6px"
                                    h="24"
                                  >
                                    <VStack
                                      h="100%"
                                      align="center"
                                      justify="center"
                                    >
                                      <Text
                                        fontSize="13px"
                                        color="#33343D"
                                        opacity="50%"
                                        fontWeight="500"
                                      >
                                        Upload Bank Statement
                                      </Text>
                                    </VStack>
                                  </Flex>
                                  {bankStatement && (
                                    <Text
                                      fontSize="12px"
                                      color="#540A45"
                                      fontWeight={700}
                                    >
                                      {bankStatement}
                                    </Text>
                                  )}
                                  {bankStatementError && (
                                    <Text color="red" fontSize="11px">
                                      {!bankStatement && bankStatementError}
                                    </Text>
                                  )}
                                </>
                              }
                            />
                          </Box>
                        )}
                      </Stack>
                    }
                    {allLoanData?.sub_type === "corporate" && (
                      <Box w={["100%"]} h="100px" mt="50px">
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                          mb="5px"
                        >
                          <label htmlFor="bankStatement">CAC Document</label>
                        </Text>
                        <Flex
                          cursor="pointer"
                          bg="linear-gradient(0deg, #F8F1F6, #F8F1F6),linear-gradient(0deg, #5B2E4F, #5B2E4F)"
                          w="100%"
                          pos="relative"
                          justify="center"
                          align="center"
                          borderRadius="6px"
                          h="100%"
                        >
                          <VStack h="100%" align="center" justify="center">
                            <Text
                              fontSize="13px"
                              color="#33343D"
                              opacity="50%"
                              fontWeight="500"
                            >
                              upload CAC Document
                            </Text>
                            <Field
                              className="upload-input hidden"
                              name="bankStatement"
                              type="file"
                              accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
                              onChange={(event) => {
                                setCacDocs(event.currentTarget.files[0].name);
                                setCacDocsFile(event.target.files[0]);
                              }}
                            />
                          </VStack>
                        </Flex>
                        {cacDocs && (
                          <Text
                            fontSize="12px"
                            color="#540A45"
                            fontWeight={700}
                          >
                            {cacDocs}
                          </Text>
                        )}
                        {cacDocsError && (
                          <Text color="red" fontSize="11px">
                            {!cacDocs && cacDocsError}
                          </Text>
                        )}
                      </Box>
                    )}
                  </VStack>
                  <Divider mt="80px" height="0.4px" bg="#5B2E4F" width="100%" />
                  <HStack spacing={3} justify="flex-end" mt="30px">
                    {allLoanData.isLoading ? (
                      <Button
                        disabled={loading}
                        color="#5B2E4F"
                        fontSize="12px"
                        mr="5px"
                        isLoading
                        border="1px solid #5B2E4"
                        loadingText="saving"
                      >
                        SAVE
                      </Button>
                    ) : (
                      <Button
                        color="#5B2E4F"
                        fontSize="12px"
                        mr="5px"
                        onClick={() => saveDetails(values)}
                      >
                        SAVE
                      </Button>
                    )}
                    <Button
                      bg="white"
                      borderRadius="6px"
                      fontSize="12px"
                      fontWeight="500"
                      color="#5B2E4F"
                      border="1px solid #5B2E4F"
                      p={["8px 32px", "13px 52px"]}
                      _hover={{
                        color: "white",
                        border: "1px solid #5B2E4F",
                        bg: "#5B2E4F",
                      }}
                      onClick={goBack}
                    >
                      BACK
                    </Button>
                    <Button
                      type="submit"
                      bg="#5B2E4F"
                      borderRadius="6px"
                      fontSize="12px"
                      fontWeight="500"
                      color="white"
                      p={["8px 32px", "13px 52px"]}
                      _hover={{
                        color: "#5B2E4F",
                        border: "1px solid #5B2E4F",
                        bg: "white",
                      }}
                    >
                      NEXT
                    </Button>
                  </HStack>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Flex>
  );
};

export default SecondForm;

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 50,
  },
  preview: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
  },
  image: { maxWidth: "200px", maxHeight: "100px" },
};
