import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Divider,
  HStack,
  Button,
  Img,
} from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";
import loanService from "../services/loanAction";
import LoadingScreen from "../components/block-components/loader/LoadingScreen";
import { useNavigate } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import { fbAnalyticsApp } from "../fbanalytics";
import * as amplitude from "@amplitude/analytics-browser";

function Review() {
  const { id } = useParams();
  const [allLoanData, setAllLoanData] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getLoanInfo = async () => {
      if (!id) {
        navigate("/");
      }
      setLoading(true);
      const res = await loanService.getLoanApplicationDetails(id);
      if (res) {
        setLoading(false);
        setAllLoanData(res);
        const analytics = getAnalytics(fbAnalyticsApp);
        logEvent(analytics, "VIEWED_LOAN_REVIEW_PAGE", allLoanData);
        amplitude.track("VIEWED_LOAN_REVIEW_PAGE", allLoanData);
      }

      if (!res) {
        setLoading(false);
        navigate("/");
      }
    };
    getLoanInfo();
    // eslint-disable-next-line
  }, [navigate, id]);

  return (
    <section>
      {loading && <LoadingScreen />}

      {allLoanData && (
        <Flex Flex justify="center">
          <Box
            Box
            w={["100%", "80%", "60%"]}
            boxShadow="0px 4px 12px 0px #D7D7D740"
            border="0.5px solid #EFEAED"
            borderRadius="10px"
          >
            <Text
              textAlign={"center"}
              fontWeight={"extrabold"}
              fontFamily={"satoshi"}
              paddingY={"10"}
            >
              TRACK APPLICATION STATUS - {id}
            </Text>

            <Flex justify="end" align="center" flexWrap="wrap" w="100%">
              <Box>
                <Button color={"#fff"} bg={"#5B2E4F"}>
                  DOWNLOAD THE HERVEST APP TODAY
                </Button>
                <Link to="https://hervestng.app.link">
                  <Flex p="20px" scaleX={"10px"}>
                    <Box pr={5}>
                      <Img src="https://staging--hervestweb-v2.netlify.app/google.svg" />
                    </Box>
                    <Img src="https://staging--hervestweb-v2.netlify.app/apple.svg" />
                  </Flex>
                </Link>
              </Box>
            </Flex>

            <Text
              p="20px"
              fontSize={["12px", "14px"]}
              fontWeight={["bold", "extrabold"]}
              color="#33343D"
            >
              Personal Information
            </Text>

            <Divider mt="5px" height="0.4px" bg="#5B2E4F" width="100%" />
            <Flex align="baseline" flexWrap="wrap" w="100%" p="20px">
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  First Name
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.first_name}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  last Name
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.last_name}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Maiden Name
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.alias || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  BVN
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.bvn || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Sex
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.gender || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Marital Status
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.marital_status || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Date of birth
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.dob?.substring(0, 10) || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Phone Number
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.phone || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Alternate Phone Number
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.alternate_phone || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Email Address
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.email || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Residential Address
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.address || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Nearest landmark
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.landmark || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Residential Status
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.home_ownership || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Signature
                </Text>
                <a
                  href={`${allLoanData?.signature}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text
                    paddingX={"5"}
                    paddingY={"3"}
                    backgroundColor={"#5B2E4F"}
                    rounded={"md"}
                    color="white"
                    fontWeight="extrabold"
                    fontSize="13px"
                    opacity="80%"
                  >
                    {"Preview Signature" || "-"}
                  </Text>
                </a>
              </Box>
            </Flex>
            <Text
              p="20px"
              fontSize={["12px", "14px"]}
              fontWeight={["bold", "extrabold"]}
              color="#33343D"
            >
              Employer's Information
            </Text>
            <Divider mt="10px" height="0.5px" bg="#5B2E4F" width="100%" />
            <Flex align="baseline" flexWrap="wrap" p="20px">
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Occupation
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.occupation || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Designation
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.designation || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Employer's Name
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.employer || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Office Telephone Number
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.office_no || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Alternative Phone Number
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.office_alternate_no || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Office Email Address
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.office_email || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Office Business Address
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.office_address || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Loan Amount
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.amount || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Loan Tenure
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.tenure || "-"}
                </Text>
              </Box>
              <Box p="10px" width={"100%"}>
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Purpose of Loan Request
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.purpose || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  NIN
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.nin || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Bank
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.bank_name || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Account Number
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.account_name || "-"}
                </Text>
              </Box>
            </Flex>
            <Text
              p="20px"
              fontSize={["12px", "14px"]}
              fontWeight={["500", "700"]}
              color="#33343D"
            >
              Uploaded Documents
            </Text>
            <Divider height="0.5px" bg="#5B2E4F" width="100%" />
            <Flex align="baseline" mt="10px" p="20px" flexWrap="Wrap">
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Utility Bill
                </Text>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={allLoanData?.utility_bill}
                >
                  <Text
                    paddingX={"5"}
                    paddingY={"3"}
                    backgroundColor={"#5B2E4F"}
                    rounded={"md"}
                    color="white"
                    fontWeight="extrabold"
                    fontSize="13px"
                    opacity="80%"
                  >
                    {"Preview Utility Bill" || "-"}
                  </Text>
                </a>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  PassPort Photograph
                </Text>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={allLoanData?.passport}
                >
                  <Text
                    paddingX={"5"}
                    paddingY={"3"}
                    backgroundColor={"#5B2E4F"}
                    rounded={"md"}
                    color="white"
                    fontWeight="extrabold"
                    fontSize="13px"
                    opacity="80%"
                  >
                    {"Preview Passport" || "-"}
                  </Text>
                </a>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Government ID
                </Text>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={allLoanData?.government_id}
                >
                  <Text
                    paddingX={"5"}
                    paddingY={"3"}
                    backgroundColor={"#5B2E4F"}
                    rounded={"md"}
                    color="white"
                    fontWeight="extrabold"
                    fontSize="13px"
                    opacity="80%"
                  >
                    {"Preview Government ID" || "-"}
                  </Text>
                </a>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Bank Statement
                </Text>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={allLoanData?.bank_statement}
                >
                  <Text
                    paddingX={"5"}
                    paddingY={"3"}
                    backgroundColor={"#5B2E4F"}
                    rounded={"md"}
                    color="white"
                    fontWeight="extrabold"
                    fontSize="13px"
                    opacity="80%"
                  >
                    {"Preview Bank Statement" || "-"}
                  </Text>
                </a>
              </Box>
              {allLoanData?.cacName && (
                <Box p="10px">
                  <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                    CAC Document
                  </Text>
                  <a target="_blank" rel="noreferrer" href={allLoanData?.cac}>
                    <Text
                      paddingX={"5"}
                      paddingY={"3"}
                      backgroundColor={"#5B2E4F"}
                      rounded={"md"}
                      color="white"
                      fontWeight="extrabold"
                      fontSize="13px"
                      opacity="80%"
                    >
                      {"Preview CAC" || "-"}
                    </Text>
                  </a>
                </Box>
              )}
            </Flex>
            <Text
              p="20px"
              fontSize={["12px", "14px"]}
              fontWeight={["500", "700"]}
              color="#33343D"
            >
              Next of Kin's Information
            </Text>
            <Divider height="0.5px" bg="#5B2E4F" width="100%" />
            <Flex align="baseline" mt="10px" p="20px" flexWrap="Wrap">
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Full Name
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.nok_name || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Phone Number
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.nok_phone || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Date of birth
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.nok_dob?.substring(0, 10) || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Place of work
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.nok_place_of_work || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Occupation
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.nok_occupation || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Email Address
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.nok_email || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Relationship to borrower
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.nok_relationship || "-"}
                </Text>
              </Box>
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Office Business Address
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.nok_office_address || "-"}
                </Text>
              </Box>
            </Flex>
            {allLoanData?.guarantor_name && (
              <>
                <Text
                  p="20px"
                  fontSize={["12px", "14px"]}
                  fontWeight={["500", "700"]}
                  color="#33343D"
                >
                  Guarantor
                </Text>
                <Divider height="0.5px" bg="#5B2E4F" width="100%" />
                <Flex align="baseline" mt="10px" p="20px" flexWrap="Wrap">
                  <Box p="10px">
                    <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                      Guarantor Name
                    </Text>
                    <Text
                      color="#33343D"
                      fontWeight="500"
                      fontSize="13px"
                      opacity="80%"
                    >
                      {allLoanData?.guarantor_name || "-"}
                    </Text>
                  </Box>

                  <Box p="10px">
                    <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                      Guarantor Name
                    </Text>
                    <Text
                      color="#33343D"
                      fontWeight="500"
                      fontSize="13px"
                      opacity="80%"
                    >
                      {allLoanData?.guarantor_email || "-"}
                    </Text>
                  </Box>
                </Flex>
              </>
            )}

            <Text
              p="20px"
              fontSize={["12px", "14px"]}
              fontWeight={["500", "700"]}
              color="#33343D"
            >
              Application Status
            </Text>

            <Divider mt="30px" height="0.4px" bg="#5B2E4F" width="100%" />

            <HStack justify="flex-end" mt="10px">
              <Flex flexDirection={"col"}>
                <Box marginY={"5"} paddingX={"20"}>
                  {allLoanData?.finance_approval === "approved" &&
                  !allLoanData?.rejected ? (
                    <Text
                      backgroundColor={"green.400"}
                      fontSize={"2xl"}
                      rounded={"lg"}
                      color={"white"}
                      fontWeight="extrabold"
                      paddingX={"10"}
                      paddingY={"3"}
                      opacity="80%"
                    >
                      APPROVED
                    </Text>
                  ) : allLoanData?.sales_approval === "declined" ||
                    allLoanData?.credit_approval === "declined" ||
                    allLoanData?.finance_approval === "declined" ||
                    allLoanData?.admin_approval === "declined" ? (
                    <Text
                      backgroundColor={"red.400"}
                      fontSize={"2xl"}
                      rounded={"lg"}
                      color={"white"}
                      fontWeight="extrabold"
                      paddingX={"10"}
                      paddingY={"3"}
                      opacity="80%"
                    >
                      DECLINED
                    </Text>
                  ) : allLoanData?.sales_approval === "pending" ||
                    allLoanData?.credit_approval === "pending" ||
                    allLoanData?.finance_approval === "pending" ||
                    allLoanData?.admin_approval === "pending" ? (
                    <Text
                      backgroundColor={"red.400"}
                      fontSize={"2xl"}
                      rounded={"lg"}
                      color={"white"}
                      fontWeight="extrabold"
                      paddingX={"10"}
                      paddingY={"3"}
                      opacity="80%"
                    >
                      IN REVIEW
                    </Text>
                  ) : null}
                </Box>
              </Flex>
            </HStack>
          </Box>
        </Flex>
      )}
    </section>
  );
}

export default Review;
